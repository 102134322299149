import { InformationCircleIcon } from "@heroicons/react/solid";
import { IAssets } from "../AdminHome";
import { ThemeTooltip } from "../../../components/settings/theme/ThemeTooltip";

const ClassifiedAssets = ({ assetsData }: { assetsData: IAssets }) => {
	return (
		<>
			<div className="bg-primary text-primary">
				<div className="relative isolate px-6 pt-4 lg:px-8">
					<div className="mx-auto max-w-2xl">
						<div className="text-center">
							<h1 className="text-2xl font-bold tracking-tight sm:text-4xl">{`${assetsData.isBroker ? "Broker" : "AOR"} Resource Materials`}</h1>
							<div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-x-6 justify-items-center">
								<div className="max-w-sm rounded overflow-hidden border border-default shadow-lg mb-4">
									<a href={assetsData.url} className="mb-2" rel="noreferrer" target="_blank"><img src={assetsData.image} alt="Resource Materials" /></a>
									<div className="px-6 py-4">
										<div className="font-bold text-xl mb-2">Resource Materials</div>
										<p>Please click {assetsData.image ? "the image" : "the button"} above to access your resource materials. You will be prompted to enter the following password: <div className="w-full mt-2"><span className="text-header font-bold">{assetsData.password}</span> <InformationCircleIcon className="w-5 h-5 text-header inline cursor-pointer" data-tip="This password is case-sensitive" /></div></p>
									</div>
								</div>
								<div className="max-w-sm rounded overflow-hidden border border-default shadow-lg mb-4">
									<a href={assetsData.infoUrl} className="mb-2" rel="noreferrer" target="_blank"><img src={assetsData.infoImage} alt="Support" /></a>
									<div className="px-6 py-4">
										<div className="font-bold text-xl mb-2">Support Page</div>
										<p>Click the image above to access {assetsData.isBroker ? "broker" : "association"} support page</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ThemeTooltip />
		</>
	);
};

export default ClassifiedAssets;
